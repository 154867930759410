import { UnitColumns } from '../utils';

export function getColumnThClass(column) {
  switch (column) {
    case UnitColumns.SUITE_NUMBER:
      return 'fixed-column';

    default:
      return '';
  }
}

export function getColumnTdClass(column) {
  switch (column) {
    case UnitColumns.SUITE_NUMBER:
      return 'fixed-column clickable-column p-0';

    default:
      return '';
  }
}
