import React from 'react';
import i18n from 'i18n-js';
import { RiMoneyDollarBoxLine } from 'react-icons/ri';
import { FiFileText } from 'react-icons/fi';
import { connect } from 'react-redux';

import SubMenu from '../components/SubMenu';
import { Menus } from '../utils';
import SidebarMenuItem from '../components/SidebarMenuItem';
import DepositsIcon from '../../../../assets/icons/sidebar-nav/DepositsIcon';
import OffersIcon from '../../../../assets/icons/sidebar-nav/OffersIcon';

const i18nOpts = { scope: 'layout.admin.sidebar.salesMenu.index' };

const SalesMenu = ({ currentCompany }) => {
  const { buyNowSettings } = currentCompany;
  const { enabledOffers, enabledDeposits, enabledPriceNegotiation } = buyNowSettings || {};
  if (!enabledOffers && !enabledDeposits) return null;

  return (
    <SubMenu
      title={i18n.t('title', i18nOpts)}
      menu={Menus.SALES}
      IconElement={<RiMoneyDollarBoxLine size="22" />}
    >
      {enabledOffers && (
        <>
          <SidebarMenuItem
            to="/admin/sales/deals"
            Icon={<OffersIcon />}
            title={i18n.t('menus.deals', i18nOpts)}
          />

          {enabledPriceNegotiation && (
            <SidebarMenuItem
              to="/admin/sales/contracts"
              Icon={<FiFileText size="22" />}
              title={i18n.t('menus.contracts', i18nOpts)}
            />
          )}
        </>
      )}

      {enabledDeposits && (
        <SidebarMenuItem
          to="/admin/deposits"
          Icon={<DepositsIcon />}
          title={i18n.t('menus.deposits', i18nOpts)}
        />
      )}
    </SubMenu>
  );
};

SalesMenu.propTypes = {};

SalesMenu.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(SalesMenu);
