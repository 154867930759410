import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const SuiteNumber = ({ unit }) => {
  const { name } = unit;

  return (
    <Link to={`/admin/units/${unit.id}`} className="col-gray-600 font-weight-600 p-3 d-block">
      {name}
    </Link>
  );
};

SuiteNumber.propTypes = {
  unit: PropTypes.objectOf(PropTypes.any).isRequired
};

SuiteNumber.defaultProps = {};

export default SuiteNumber;
