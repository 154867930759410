import React from 'react';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../../../../../../../utils/currencyUtils';
import { getQPTotalPrice } from '../../../../../../../../utils/quickPossessionUtils';

const Price = ({ quickPossession }) => (
  <div className="font-weight-500 col-gray-900">
    {formatCurrency(getQPTotalPrice(quickPossession))}
  </div>
);

Price.propTypes = {
  quickPossession: PropTypes.objectOf(PropTypes.any).isRequired
};

Price.defaultProps = {};

export default Price;
