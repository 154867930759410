import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonGroup,
  Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row
} from 'reactstrap';
import Switch from 'rc-switch';
import i18n from 'i18n-js';

import { parseBoolean } from '../../../../../../utils/parserUtils';
import { isMasterAdmin, isTecSupport } from '../../../../../../utils/authUtils';
import { DownPaymentTypes, ValidUntilPeriods } from '../../../../../../constants';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.salesConfig.index' };

const LOAN_TERMS = [60, 120, 180, 240, 300, 360];

const SalesConfig = ({ form, error, onTextChange }) => {
  const settings = form.settings || {};
  const design = settings.design || {};
  const validUntil = design.validUntil || {};
  const mortgageRateSettings = form.mortgageRateSettings || {};

  const onChangeSwitch = (name, checked) => {
    onTextChange({ target: { name, value: checked } });
  };

  const onChangeDesignSetting = (event) => {
    const { name, value } = event.target;

    const newSettings = {
      ...settings,
      design: { ...design, [name]: value }
    };
    onTextChange({ target: { name: 'settings', value: newSettings } });
  };

  const onChangeMortgageSetting = (event) => {
    const { name, value } = event.target;
    const newMortgageSettings = { ...mortgageRateSettings, [name]: value };
    onTextChange({ target: { name: 'mortgageRateSettings', value: newMortgageSettings } });
  };

  const onDownPaymentTypeChange = (downPaymentType) => {
    onChangeMortgageSetting({ target: { name: 'downPaymentType', value: downPaymentType } });
  };

  const onChangeValidUntil = (event) => {
    const { name, value } = event.target;

    const newSettings = {
      ...settings,
      design: { ...design, validUntil: { ...validUntil, [name]: value } }
    };
    onTextChange({ target: { name: 'settings', value: newSettings } });
  };

  const onToggleCompanyType = (selectedOption) => {
    onTextChange({ target: { name: 'enableHomeSales', value: selectedOption === 'enableHomeSales' } });
    onTextChange({ target: { name: 'enableHomeRental', value: selectedOption === 'enableHomeRental' } });
  };

  const isMasterAdminRole = isMasterAdmin();
  const isTecSupportRole = isTecSupport();
  const canViewMasterSettings = isMasterAdminRole || isTecSupportRole;
  const isPriceNegotiationEnabled = form.buyNowSettings?.enabledPriceNegotiation;

  return (
    <>
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row>
            <Label sm={5} className="py-0">
              {i18n.t('companyType', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.companyType', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <div role="button" aria-hidden onClick={() => onToggleCompanyType('enableHomeSales')} className="mb-3">
                <Switch checked={parseBoolean(form.enableHomeSales)} />
                <span className="font-weight-500 ml-2">{i18n.t('companyTypes.homeSales', i18nOpts)}</span>
              </div>
              <div role="button" aria-hidden onClick={() => onToggleCompanyType('enableHomeRental')}>
                <Switch checked={parseBoolean(form.enableHomeRental)} />
                <span className="font-weight-500 ml-2">{i18n.t('companyTypes.homeRentals', i18nOpts)}</span>
              </div>
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <hr className="mt-0" />
      <Row>
        <Col md={12} lg={9} xl={8}>
          {parseBoolean(form.enableHomeRental) ? (
            <>
              <FormGroup row>
                <Label sm={5}>{i18n.t('enableSingleFamilyHome', i18nOpts)}</Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.enableSingleFamilyHomesRental)}
                    onChange={(checked) => onChangeSwitch('enableSingleFamilyHomesRental', checked)}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={5}>{i18n.t('enableMultiFamilyHome', i18nOpts)}</Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.enableMultiFamilyHomesRental)}
                    onChange={(checked) => onChangeSwitch('enableMultiFamilyHomesRental', checked)}
                  />
                </Col>
              </FormGroup>
            </>
          ) : (
            <>
              <FormGroup row>
                <Label sm={5}>{i18n.t('enableSingleFamilyHome', i18nOpts)}</Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.enableSingleFamilyHomes)}
                    onChange={(checked) => onChangeSwitch('enableSingleFamilyHomes', checked)}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={5}>{i18n.t('enableMultiFamilyHome', i18nOpts)}</Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.enableMultiFamilyHomes)}
                    onChange={(checked) => onChangeSwitch('enableMultiFamilyHomes', checked)}
                  />
                </Col>
              </FormGroup>
            </>
          )}
        </Col>
      </Row>
      {canViewMasterSettings && (
        <>
          <hr className="mt-0" />
          <Row>
            <Col md={12} lg={9} xl={8}>
              <FormGroup row>
                <Label sm={5} className="py-0">
                  {i18n.t('enableACHPayments', i18nOpts)}
                  <span className="help-text">{i18n.t('helpTexts.enableACHPayments', i18nOpts)}</span>
                </Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.enableACHPayments)}
                    onChange={(checked) => onChangeSwitch('enableACHPayments', checked)}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      {!isPriceNegotiationEnabled && (
        <>
          <hr className="mt-0" />
          <Row>
            <Col md={12} lg={9} xl={8}>
              <FormGroup row>
                <Label sm={5} className="py-0">
                  {i18n.t('fixedDepositAmount', i18nOpts)}
                  <span className="help-text">{i18n.t('helpTexts.fixedDepositAmount', i18nOpts)}</span>
                </Label>
                <Col sm={7}>
                  <div className="input-icon-prepend font-16" style={{ bottom: 'unset' }}>$</div>
                  <Input
                    type="number"
                    name="fixedDepositAmount"
                    id="fixedDepositAmount"
                    value={design.fixedDepositAmount || ''}
                    onChange={onChangeDesignSetting}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      <hr className="mt-0" />
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row>
            <Label sm={5}>{i18n.t('downPayment', i18nOpts)}</Label>
            <Col sm={7} className="d-flex gap-3">
              <div className="flex-grow-1 position-relative">
                {mortgageRateSettings.downPaymentType === DownPaymentTypes.AMOUNT && (
                  <div className="input-icon-prepend font-16" style={{ bottom: 'unset' }}>$</div>
                )}
                <Input
                  type="number"
                  name="downPayment"
                  id="downPayment"
                  value={mortgageRateSettings.downPayment || ''}
                  onChange={onChangeMortgageSetting}
                  className={mortgageRateSettings.downPaymentType === DownPaymentTypes.PERCENT ? 'has-input-icon-append' : ''}
                />
                {mortgageRateSettings.downPaymentType === DownPaymentTypes.PERCENT && (
                  <div className="input-icon-append font-16">%</div>
                )}
              </div>
              <div>
                <ButtonGroup>
                  <Button
                    color="primary"
                    outline
                    active={mortgageRateSettings.downPaymentType === DownPaymentTypes.AMOUNT}
                    onClick={() => onDownPaymentTypeChange(DownPaymentTypes.AMOUNT)}
                  >
                    $
                  </Button>
                  <Button
                    color="primary"
                    outline
                    active={mortgageRateSettings.downPaymentType === DownPaymentTypes.PERCENT}
                    onClick={() => onDownPaymentTypeChange(DownPaymentTypes.PERCENT)}
                  >
                    %
                  </Button>
                </ButtonGroup>
              </div>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={5}>{i18n.t('loanTerm', i18nOpts)}</Label>
            <Col sm={7}>
              <Input
                type="select"
                name="loanTerm"
                id="loanTerm"
                value={mortgageRateSettings.loanTerm || ''}
                onChange={onChangeMortgageSetting}
              >
                <option value="">{i18n.t('select.select')}</option>
                {
                  LOAN_TERMS.reverse().map((lt) => (
                    <option key={`loan-term-${lt}`} value={lt}>
                      {i18n.t('years', { ...i18nOpts, years: Math.round(lt / 12) })}
                    </option>
                  ))
                }
              </Input>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={5} className="py-0">
              {i18n.t('interestRate', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.interestRate', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <div className="position-relative">
                <Input
                  type="number"
                  name="interestRate"
                  id="interestRate"
                  value={mortgageRateSettings.interestRate || ''}
                  onChange={onChangeMortgageSetting}
                  className="has-input-icon-append"
                />
                <div className="input-icon-append font-16">%</div>
              </div>
            </Col>
          </FormGroup>

          {form.enableVerifiedBuyingPower && (
            <div className="text-secondary mb-2">{i18n.t('helpTexts.buyingPowerInfo', i18nOpts)}</div>
          )}
        </Col>
      </Row>
      <hr className="mt-0" />
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row>
            <Label sm={5} className="py-0">
              {i18n.t('enableValidUntil', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.enableValidUntil', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Switch
                checked={parseBoolean(form.enableValidUntil)}
                onChange={(checked) => onChangeSwitch('enableValidUntil', checked)}
              />
            </Col>
          </FormGroup>

          {parseBoolean(form.enableValidUntil) && (
            <FormGroup row>
              <Label sm={5} className="py-0">
                {i18n.t('validUntilDate', i18nOpts)}
                <span className="help-text">{i18n.t('helpTexts.validUntilDate', i18nOpts)}</span>
              </Label>
              <Col sm={7}>
                <InputGroup>
                  <Input
                    type="number"
                    name="amount"
                    id="amount"
                    value={validUntil.amount || ''}
                    onChange={onChangeValidUntil}
                    placeholder={7}
                  />
                  <InputGroupAddon addonType="append">
                    <Input
                      type="select"
                      name="period"
                      value={validUntil.period || ''}
                      onChange={onChangeValidUntil}
                      className="rounded-left-0"
                    >
                      {
                        ValidUntilPeriods.map((c) => (
                          <option key={`valid-until-period-${c.value}`} value={c.value}>
                            {c.label}
                          </option>
                        ))
                      }
                    </Input>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </FormGroup>
          )}
        </Col>
      </Row>
    </>
  );
};

SalesConfig.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.any),
  onTextChange: PropTypes.func
};

SalesConfig.defaultProps = {
  error: null,
  onTextChange: () => {}
};

export default SalesConfig;
