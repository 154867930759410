import i18n from 'i18n-js';

import api from '../../../../../../api';
import {
  listOnfidoVerificationDocumentsQuery
} from '../../../../../../graphql';
import { IdDocumentTypes, PreApprovalVerificationMethods } from '../../../../../../constants';
import { getIdDocumentTypeName } from '../../../../../../utils/enumUtils';

const i18nOpts = { scope: 'components.admin.customers.show.details.documents.utils' };

export const CustomerDocumentTypes = Object.freeze({
  PRE_APPROVAL: 'preApproval',
  ONFIDO_DOCUMENT_SELFIE: 'onfidoDocumentSelfie',
  ONFIDO_DOCUMENT_FRONT_PHOTO: 'onfidoDocumentFrontPhoto',
  ONFIDO_DOCUMENT_BACK_PHOTO: 'onfidoDocumentBackPhoto',
  ONFIDO_DOCUMENT_REPORT: 'onfidoDocumentReport',
});

export async function listOnfidoVerificationDocumentsAsync(id) {
  return api.graphql(listOnfidoVerificationDocumentsQuery, { id })
    .then(({ data: { items } }) => items);
}

export function getPreApprovalDocument(customer, currentCompany) {
  const { isFinancialVerified, user, companyContact } = customer;
  const preApproval = user?.preApproval;

  const isPreApprovalManual = preApproval?.verificationMethod
    === PreApprovalVerificationMethods.MANUAL;
  const isCurrentCompanyPreApproval = preApproval?.companyId === currentCompany.id;
  const isFinancialVerifiedAndGranted = (isFinancialVerified
      && companyContact.verifiedFinancialGranted)
    || (isPreApprovalManual && isCurrentCompanyPreApproval);

  if (!isFinancialVerifiedAndGranted || !isPreApprovalManual) return null;

  const { documentUrl } = preApproval;

  const fileExtension = documentUrl.split('.').pop();
  const fileName = `${i18n.t('preApproval', i18nOpts)} (${customer.name}).${fileExtension}`;

  return {
    documentType: CustomerDocumentTypes.PRE_APPROVAL,
    fileName,
    documentName: i18n.t('preApproval', i18nOpts),
    fileSize: null,
    createdAt: preApproval.createdAt,
    data: {
      downloadUrl: documentUrl,
    }
  };
}

function getOnfidoDocumentPhoto(onfidoDocument, customer) {
  const {
    side, fileType, fileSize, createdAt, type
  } = onfidoDocument;
  const documentName = getIdDocumentTypeName(type);
  let documentSide;
  let documentType;

  if (side === 'back') {
    documentType = CustomerDocumentTypes.ONFIDO_DOCUMENT_BACK_PHOTO;
    documentSide = i18n.t('onfidoDocumentBack', i18nOpts);
  } else {
    documentType = CustomerDocumentTypes.ONFIDO_DOCUMENT_FRONT_PHOTO;
    documentSide = i18n.t('onfidoDocumentFront', i18nOpts);
  }
  const fileName = i18n.t('onfidoDocumentPhoto', {
    ...i18nOpts, documentSide, documentName, customerName: customer.name, fileType
  });

  return {
    documentType,
    fileName,
    documentName,
    fileSize,
    createdAt,
    data: onfidoDocument
  };
}

function getOnfidoDocumentSelfie(onfidoDocument, customer) {
  const { fileSize, createdAt } = onfidoDocument;

  const fileType = onfidoDocument.fileName.split('.').pop();
  const fileName = i18n.t('onfidoDocumentSelfie', { ...i18nOpts, customerName: customer.name, fileType });
  return {
    documentType: CustomerDocumentTypes.ONFIDO_DOCUMENT_SELFIE,
    fileName,
    documentName: i18n.t('onfidoCustomerPhoto', i18nOpts),
    fileSize,
    createdAt,
    data: onfidoDocument
  };
}

function getOnfidoDocumentReport(onfidoDocument, customer) {
  const { fileSize, fileType, createdAt } = onfidoDocument;

  const fileName = i18n.t('onfidoDocumentReport', { ...i18nOpts, customerName: customer.name, fileType });
  return {
    documentType: CustomerDocumentTypes.ONFIDO_DOCUMENT_REPORT,
    fileName,
    documentName: i18n.t('onfidoIdVerification', i18nOpts),
    fileSize,
    createdAt,
    data: onfidoDocument
  };
}

export function parseOnfidoDocuments(onfidoDocuments, customer) {
  return onfidoDocuments.map((onfidoDocument) => {
    const { type, fileName } = onfidoDocument;
    const isIdDocumentPhoto = Object.values(IdDocumentTypes).includes(type);
    if (isIdDocumentPhoto) return getOnfidoDocumentPhoto(onfidoDocument, customer);
    if (type === 'selfie') return getOnfidoDocumentSelfie(onfidoDocument, customer);
    if (fileName?.includes('check')) return getOnfidoDocumentReport(onfidoDocument, customer);

    return null;
  });
}
