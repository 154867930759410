import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import ListItem from './ListItem';

const TileView = ({ quickPossessions }) => (
  <Row>
    {
      quickPossessions.map((q) => (
        <Col lg="4" key={`quickPossession-${q.id}`}>
          <ListItem quickPossession={q} />
        </Col>
      ))
    }
  </Row>
);

TileView.propTypes = {};

TileView.defaultProps = {};

export default connect((store) => ({
  quickPossessions: store.quickPossessions.quickPossessions,
}))(TileView);
