import React, { useEffect, useMemo, useState } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'gatsby';

import {
  getQuickPossessions,
  setReloadQuickPossessions,
  cleanQuickPossessions,
  setViewMode,
  setQuickPossessionColumns
} from '../../../../store/actions/quickPossessionActions';
import ListEmpty from '../../../common/ListEmpty';
import TileView from './TileView';
import ListView from './ListView';
import { ModalNames, ViewModes } from '../../../../constants';
import ListPagination from '../../../common/ListPagination';
import ModalService from '../../../../services/ModalService';
import QuickPossessionFormModal from '../components/QuickPossessionFormModal';
import SearchBar from '../../../common/SearchBar';
import Loading from '../../../ui/Loading';
import ViewModesDropdown from '../../../global/ViewModesDropdown';
import EditColumnsButton from './components/EditColumnsButton';
import QuickPossessionColumnsPickerModal from './components/QuickPossessionColumnsPickerModal';
import FiltersButton from './components/FiltersButton';
import QuickPossessionFiltersModal from './components/QuickPossessionFiltersModal';
import { getQuickPossessionFilters } from './utils';
import { getPathname } from '../../../../utils/paramsUtils';
import Breadcrumbs from '../../../common/Breadcrumbs';

const i18nOpts = { scope: 'components.admin.quickPossessions.list.index' };

const List = ({
  loading, quickPossessions, viewMode, currentCompany, reloadQuickPossessions, pagination,
  currentUser, columns, filters, ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const [query, setQuery] = useState(null);
  const [initialColumnsSet, setInitialColumnsSet] = useState(false);

  const showOnlyScheduledToArchive = useMemo(() => {
    const pathname = getPathname();
    return pathname.includes('/scheduledToArchive');
  }, []);

  const loadQuickPossessions = (page = 1) => {
    const filter = getQuickPossessionFilters(
      currentCompany,
      filters,
      query,
      showOnlyScheduledToArchive
    );

    const variables = { filter, page };
    props.getQuickPossessions(variables)
      .finally(() => {
        setMounted(true);
        props.setReloadQuickPossessions(false);
      });
  };

  const onChangePage = (page) => {
    loadQuickPossessions(page);
  };

  const onAdd = () => {
    ModalService.open(ModalNames.QUICK_POSSESSION_FORM);
  };

  const onSearch = (e, q) => {
    e.preventDefault();

    setQuery(q);
  };

  const onChangeViewMode = (newMode) => {
    props.setViewMode(newMode);
  };

  const setInitialColumns = () => {
    const { quickPossessions: initialColumns = [] } = currentUser.columnSettings || {};
    if (initialColumns && initialColumns.length) props.setQuickPossessionColumns(initialColumns);
    setInitialColumnsSet(true);
  };

  const getBreadcrumbItems = () => {
    const items = [
      { url: '/admin/quickPossessions', text: i18n.t('title', i18nOpts) },
    ];
    if (showOnlyScheduledToArchive) items.push({ url: '/admin/quickPossessions/scheduledToArchive', text: i18n.t('scheduledToArchive', i18nOpts) });
    return items;
  };

  useEffect(() => {
    setInitialColumns();
  }, []);

  useEffect(() => {
    if (initialColumnsSet) loadQuickPossessions();
  }, [filters, query, columns, initialColumnsSet]);

  useEffect(() => {
    if (!reloadQuickPossessions) return;

    loadQuickPossessions();
  }, [reloadQuickPossessions]);

  const breadcrumbItems = getBreadcrumbItems();

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="mb-4">
        <Breadcrumbs items={breadcrumbItems} />
      </div>

      <div className="d-flex mb-4 align-items-center flex-wrap gap-3">
        <div className="flex-grow-1">
          <SearchBar
            onSubmit={onSearch}
            inputProps={{ bsSize: 'md', placeholder: i18n.t('searchBarPlaceholder', i18nOpts) }}
          />
        </div>
        <div className="d-flex justify-content-end align-items-center flex-wrap gap-3">
          <ViewModesDropdown viewMode={viewMode} onChange={onChangeViewMode} />

          {viewMode === ViewModes.LIST && <EditColumnsButton />}

          <FiltersButton />

          <Button color="primary" onClick={onAdd}>
            <i className="fas fa-plus mr-2" />
            {i18n.t('buttons.add', i18nOpts)}
          </Button>
        </div>
      </div>

      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={quickPossessions} />

          {quickPossessions.length > 0 && (
            <div className="mb-4">
              {viewMode === ViewModes.GRID ? (
                <TileView />
              ) : (
                <ListView />
              )}
            </div>
          )}

          <div className="d-flex justify-content-between align-items-center mt-4">
            <div>
              {!showOnlyScheduledToArchive && (
                <Link to="/admin/quickPossessions/scheduledToArchive" className="text-decoration-underline">
                  {i18n.t('scheduledToArchive', i18nOpts)}
                </Link>
              )}
            </div>
            <div>
              <ListPagination pagination={pagination} onPress={onChangePage} />
            </div>
          </div>
        </>
      )}

      <QuickPossessionFormModal />
      <QuickPossessionColumnsPickerModal />
      <QuickPossessionFiltersModal />
    </div>
  );
};

export default connect((store) => ({
  quickPossessions: store.quickPossessions.quickPossessions,
  viewMode: store.quickPossessions.viewMode,
  pagination: store.quickPossessions.pagination,
  loading: store.quickPossessions.getQuickPossessions.loading,
  reloadQuickPossessions: store.quickPossessions.reloadQuickPossessions,
  currentCompany: store.companies.currentCompany,
  currentUser: store.users.currentUser,
  columns: store.quickPossessions.columns,
  filters: store.quickPossessions.filters
}), {
  getQuickPossessions,
  setReloadQuickPossessions,
  cleanQuickPossessions,
  setViewMode,
  setQuickPossessionColumns
})(List);
