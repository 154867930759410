import i18n from 'i18n-js';
import { navigate } from 'gatsby';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import {
  Button, Col, Form, FormGroup, Input, Label, Row, Spinner
} from 'reactstrap';
import { connect } from 'react-redux';
import v from 'voca';

import { getParam } from '../../../../utils/paramsUtils';
import { hasFormValues } from '../../../../utils/formUtils';
import { getLocalImageUrl } from '../../../../utils/imageUtils';
import ImageViewer from '../../../common/ImageViewer';
import { deleteProject, getProject, saveProject } from '../../../../store/actions/projectActions';
import DeleteButton from '../../../global/DeleteButton';
import { getError } from '../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../ui/InputError';
import { stripToEmpty } from '../../../../utils/stringUtils';
import api from '../../../../api';
import { CKEditor } from '../../../ui';
import { getProjectAvailabilityName, getProjectStatusName } from '../../../../utils/projectUtils';
import { ProjectAvailabilities, ProjectBuildAndPriceStartingPointTypes, ProjectStatuses } from '../../../../constants';
import { parseBoolean } from '../../../../utils/parserUtils';

const i18nOpts = { scope: 'components.admin.projects.form.index' };

const ProjectTypes = Object.freeze([
  { value: 'RENTAL', label: i18n.t('types.rental', i18nOpts) },
  { value: 'FOR_SALE', label: i18n.t('types.forSale', i18nOpts) }
]);

function fetchCitiesAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(`
    query ListCities($filter: CityFilterInput) {
      items: listCities(filter: $filter) {
        id
        name
      }
    }
  `, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

function onCancel() {
  navigate('/admin/projects');
}

class ProjectForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      form: {
        buildAndPriceStartingPoint: ProjectBuildAndPriceStartingPointTypes.MODEL
      },
      image: undefined,
      saving: false,
      error: null,
      cities: []
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);
  }

  componentDidMount() {
    this.loadCities();

    const id = parseInt(getParam('id'), 10);
    this.setState({ id }, this.loadProject);
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;

    form[name] = value;
    this.setState({ form });
  }

  onImageChange(event) {
    const { name } = event.target;
    const image = event.target.files[0];
    this.setState({ [name]: image });
  }

  onDeleteImage(imageType) {
    this.setState({ [imageType]: null });
  }

  onSave() {
    const { props } = this;
    const { form, image } = this.state;
    const { currentCompany } = this.props;

    delete form.imageUrl;

    const input = {
      ...form,
      name: stripToEmpty(form.name),
      cityId: parseInt(form.cityId, 10) || 0,
      enableMakeOffer: parseBoolean(form.enableMakeOffer)
    };

    if (this.isNew()) input.companyId = currentCompany.id;

    this.setState({ saving: true });

    const variables = { input, image };
    props.saveProject(variables)
      .then(() => {
        navigate('/admin/projects');
      })
      .catch((e) => {
        const error = getError(e);
        this.setState({ error });
        if (v.isString(error)) toast.error(error);
      })
      .finally(() => {
        this.setState({ saving: false });
      });
  }

  onDelete() {
    const { props } = this;
    const { project } = this.props;

    props.deleteProject(project.id)
      .then(() => {
        navigate('/admin/projects');
      });
  }

  getPreviewImageUrls() {
    const { project } = this.props;
    const { image } = this.state;
    return image === null ? null : (getLocalImageUrl(image) || project?.imageUrl);
  }

  loadProject() {
    const { props } = this;
    const { id } = this.state;

    if (this.isNew()) return;

    props.getProject(id)
      .then(() => {
        const { project } = this.props;

        if (project) {
          const {
            name, city, subRegion, address, imageUrl, availability, buildingType, type,
            description, longDescription, status, enableMakeOffer, buildAndPriceStartingPoint
          } = project;
          const form = {
            id,
            name,
            cityId: city?.id,
            subRegion,
            address,
            imageUrl,
            availability,
            status,
            buildingType,
            type,
            description,
            longDescription,
            enableMakeOffer,
            buildAndPriceStartingPoint
          };
          this.setState({ form });
        } else navigate('/admin/projects');
      })
      .catch(() => navigate('/admin/projects'));
  }

  loadCities() {
    const { currentCompany } = this.props;
    fetchCitiesAsync(currentCompany.id)
      .then((cities) => {
        this.setState({ cities });
      })
      .catch(() => {});
  }

  hasFormValues() {
    const { form, image } = this.state;
    const hasValues = hasFormValues(form);

    return hasValues || !!image;
  }

  isNew() {
    const { id } = this.state;
    return !id;
  }

  render() {
    const {
      form, saving, error, cities
    } = this.state;

    const isNew = this.isNew();
    const hasValues = this.hasFormValues();

    const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);

    const imageUrl = this.getPreviewImageUrls();

    return (
      <div>
        <Helmet title={title} />

        <h2 className="mb-4">{title}</h2>

        <Form>
          <Row className="mb-4">
            <Col xs="12" sm="12" md="6" lg="4">
              <FormGroup>
                <Label for="name">{i18n.t('name', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={form.name || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'name')}
                />
                <InputError name="name" error={error} />
              </FormGroup>

              <FormGroup>
                <Label for="cityId">{i18n.t('cityId', i18nOpts)}</Label>
                <Input
                  type="select"
                  name="cityId"
                  id="cityId"
                  value={form.cityId || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'cityId')}
                >
                  <option value="">{i18n.t('select.select')}</option>
                  {
                    cities.map((city) => (
                      <option key={`city-option-${city.id}`} value={city.id}>
                        {city.name}
                      </option>
                    ))
                  }
                </Input>
                <InputError error={error} name="cityId" />
              </FormGroup>

              <FormGroup>
                <Label for="subRegion">{i18n.t('subRegion', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="subRegion"
                  id="subRegion"
                  value={form.subRegion || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'subRegion')}
                />
                <InputError name="subRegion" error={error} />
              </FormGroup>

              <FormGroup>
                <Label for="address">{i18n.t('address', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  value={form.address || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'address')}
                />
                <InputError name="address" error={error} />
              </FormGroup>

              <FormGroup>
                <Label for="buildingType">{i18n.t('buildingType', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="buildingType"
                  id="buildingType"
                  value={form.buildingType || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'buildingType')}
                />
                <InputError name="buildingType" error={error} />
              </FormGroup>

              <FormGroup>
                <Label for="image">{i18n.t('image', i18nOpts)}</Label>
                { imageUrl && (
                  <ImageViewer url={imageUrl} onDelete={() => this.onDeleteImage('image')} />
                )}
                <Input
                  type="file"
                  name="image"
                  id="image"
                  accept="image/*"
                  onChange={this.onImageChange}
                  invalid={isInputInvalid(error, 'image')}
                />
                <InputError name="image" error={error} />
              </FormGroup>

              <FormGroup>
                <Label for="description">{i18n.t('description', i18nOpts)}</Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  value={form.description || ''}
                  onChange={this.onTextChange}
                  maxLength="500"
                  rows={5}
                  invalid={isInputInvalid(error, 'description')}
                />
                <InputError name="description" error={error} />
              </FormGroup>

              <FormGroup className="mb-5">
                <Label for="longDescription">{i18n.t('longDescription', i18nOpts)}</Label>
                <CKEditor
                  data={form.longDescription || ''}
                  name="longDescription"
                  onTextChange={this.onTextChange}
                />
              </FormGroup>

              <FormGroup check className="mb-3">
                <Label check>
                  <Input
                    type="checkbox"
                    name="enableMakeOffer"
                    id="enableMakeOffer"
                    value={!parseBoolean(form.enableMakeOffer)}
                    checked={parseBoolean(form.enableMakeOffer)}
                    onChange={this.onTextChange}
                  />
                  {i18n.t('enableMakeOffer', i18nOpts)}
                </Label>
              </FormGroup>
            </Col>
            <Col xs="12" sm="12" md="6" lg={{ offset: 2, size: 4 }}>
              <FormGroup>
                <Label for="availability">{i18n.t('availability', i18nOpts)}</Label>
                <Input
                  type="select"
                  name="availability"
                  id="availability"
                  value={form.availability || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'availability')}
                >
                  {
                    Object.values(ProjectAvailabilities).map((availability) => (
                      <option key={`availability-option-${availability}`} value={availability}>
                        {getProjectAvailabilityName(availability)}
                      </option>
                    ))
                  }
                </Input>
                <InputError name="availability" error={error} />
              </FormGroup>

              <FormGroup>
                <Label for="type">{i18n.t('type', i18nOpts)}</Label>
                <Input
                  type="select"
                  name="type"
                  id="type"
                  value={form.type || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'type')}
                >
                  {
                  ProjectTypes.map((t) => (
                    <option key={`type-option-${t.value}`} value={t.value}>
                      {t.label}
                    </option>
                  ))
                }
                </Input>
                <InputError name="type" error={error} />
              </FormGroup>

              <FormGroup>
                <Label for="status">{i18n.t('status', i18nOpts)}</Label>
                <Input
                  type="select"
                  name="status"
                  id="status"
                  value={form.status || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'status')}
                >
                  {
                    Object.values(ProjectStatuses).map((status) => (
                      <option key={`status-option-${status}`} value={status}>
                        {getProjectStatusName(status)}
                      </option>
                    ))
                  }
                </Input>
                <InputError name="status" error={error} />
              </FormGroup>

              <div className="mb-3">
                <Label className="d-block">{i18n.t('flowForBuildAndPrice', i18nOpts)}</Label>
                <FormGroup check>
                  <Label for="startFromModelSelection">
                    <Input
                      type="radio"
                      name="buildAndPriceStartingPoint"
                      id="startFromModelSelection"
                      checked={(form.buildAndPriceStartingPoint
                        === ProjectBuildAndPriceStartingPointTypes.MODEL)}
                      value={ProjectBuildAndPriceStartingPointTypes.MODEL}
                      onChange={this.onTextChange}
                    />
                    {i18n.t('startFromModelSelection', i18nOpts)}
                  </Label>
                </FormGroup>

                <FormGroup check>
                  <Label check for="startFromUnitSelection">
                    <Input
                      type="radio"
                      name="buildAndPriceStartingPoint"
                      id="startFromUnitSelection"
                      checked={(form.buildAndPriceStartingPoint
                        === ProjectBuildAndPriceStartingPointTypes.UNIT)}
                      value={ProjectBuildAndPriceStartingPointTypes.UNIT}
                      onChange={this.onTextChange}
                    />
                    {i18n.t('startFromUnitSelection', i18nOpts)}
                  </Label>
                </FormGroup>
              </div>
            </Col>
          </Row>

          <Button color="primary" onClick={this.onSave} className="mr-3" disabled={saving || !hasValues}>
            {saving && (<Spinner size="sm" className="mr-2" />)}
            {i18n.t('buttons.save')}
          </Button>
          <Button outline color="secondary" onClick={onCancel} className="mr-3">
            {i18n.t('buttons.cancel')}
          </Button>
          {!isNew && (
            <DeleteButton onDelete={this.onDelete} />
          )}
        </Form>
      </div>
    );
  }
}

export default connect((store) => ({
  project: store.projects.project,
  currentCompany: store.companies.currentCompany
}), {
  saveProject,
  getProject,
  deleteProject
})(ProjectForm);
