import React from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import ListEmpty from '../../../../../common/ListEmpty';
import { formatTimestamp } from '../../../../../../utils/dateUtils';

const i18nOpts = { scope: 'components.admin.units.show.observers.startedReserveFlow.index' };

const SavedHomes = ({ observers }) => (
  <div>
    <h4>{i18n.t('title', i18nOpts)}</h4>

    <ListEmpty items={observers} />

    {observers && observers.length > 0 && (
      <Table bordered responsive className="mb-4">
        <thead>
          <tr>
            <th>{i18n.t('list.name', i18nOpts)}</th>
            <th>{i18n.t('list.email', i18nOpts)}</th>
            <th>{i18n.t('list.phone', i18nOpts)}</th>
            <th>{i18n.t('list.date', i18nOpts)}</th>
          </tr>
        </thead>
        <tbody>
          {
            observers.map((observer) => (
              <tr key={observer.id}>
                <td>{observer.contact?.name}</td>
                <td>{observer.contact?.email}</td>
                <td>{observer.contact?.phone}</td>
                <td>{formatTimestamp(observer.reservationStartedAt)}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    )}
  </div>
);

SavedHomes.propTypes = {
  observers: PropTypes.arrayOf(PropTypes.object).isRequired
};

SavedHomes.defaultProps = {};

export default SavedHomes;
