import React from 'react';
import PropTypes from 'prop-types';

import { CustomerDocumentTypes } from '../../utils';
import PreApproval from './PreApproval';
import OnfidoDocument from './OnfidoDocument';

const DownloadButton = ({ document }) => {
  const { documentType } = document;

  switch (documentType) {
    case CustomerDocumentTypes.PRE_APPROVAL:
      return <PreApproval document={document} />;

    case CustomerDocumentTypes.ONFIDO_DOCUMENT_SELFIE:
    case CustomerDocumentTypes.ONFIDO_DOCUMENT_FRONT_PHOTO:
    case CustomerDocumentTypes.ONFIDO_DOCUMENT_BACK_PHOTO:
    case CustomerDocumentTypes.ONFIDO_DOCUMENT_REPORT:
      return <OnfidoDocument document={document} />;

    default:
      return null;
  }
};

DownloadButton.propTypes = {
  document: PropTypes.objectOf(PropTypes.any).isRequired
};

DownloadButton.defaultProps = {};

export default DownloadButton;
