import React, { useState } from 'react';

import './styles.scss';
import Loading from '../../ui/Loading';

const KNOWLEDGE_URL = 'https://help.theownly.io/knowledge';

const KnowledgeHubFrame = () => {
  const [loading, setLoading] = useState(true);
  const onLoad = () => {
    setLoading(false);
  };
  return (
    <div>
      <Loading loading={loading} fullScreen />

      <iframe
        title="knowledge-hub-frame"
        id="knowledge-frame"
        src={KNOWLEDGE_URL}
        frameBorder="0"
        height="100%"
        width="100%"
        onLoad={onLoad}
      />
    </div>
  );
};

export default KnowledgeHubFrame;
