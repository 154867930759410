import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

import { formatNumber } from '../../../../../../../../utils/currencyUtils';

const Size = ({ quickPossession }) => {
  const { squareFootage } = quickPossession;

  if (v.isBlank(squareFootage)) return null;

  return (
    <div>
      {formatNumber(squareFootage)}
    </div>
  );
};

Size.propTypes = {
  quickPossession: PropTypes.objectOf(PropTypes.any).isRequired
};

Size.defaultProps = {};

export default Size;
