import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import { navigate } from 'gatsby';

import Layout from '../layout/admin';
import Overview from '../components/admin/Overview';
import Communities from '../components/admin/Communities';
import CommunityForm from '../components/admin/Communities/Form';
import Community from '../components/admin/Communities/Show';
import Models from '../components/admin/Models';
import ModelForm from '../components/admin/Models/Form';
import Model from '../components/admin/Models/Show';
import Settings from '../components/admin/Settings';
import Integrations from '../components/admin/Integrations';
import CustomCode from '../components/admin/Integrations/CustomCode';
import HubSpot from '../components/admin/Integrations/HubSpot';
import MortgageRate from '../components/admin/MortgageRate';
import Companies from '../components/admin/companies/List';
import EditCompany from '../components/admin/companies/EditCompany';
import CompanySettings from '../components/admin/companies/Settings';
import Users from '../components/admin/users/List';
import UserShow from '../components/admin/users/Show';
import { isBrowser } from '../utils/windowUtils';
import AuthService from '../services/AuthService';
import Palettes from '../components/admin/palettes/List';
import PaletteForm from '../components/admin/palettes/Form';
import Palette from '../components/admin/palettes/Show';
import ProductTypes from '../components/admin/productTypes/List';
import ProductTypeForm from '../components/admin/productTypes/Form';
import ProductType from '../components/admin/productTypes/Show';
import ImportData from '../components/admin/Integrations/ImportData';
import ExportData from '../components/admin/Integrations/ExportData';
import Documents from '../components/admin/Documents';
import Zapier from '../components/admin/Integrations/Zapier';
import GoogleSitemap from '../components/admin/Integrations/GoogleSitemap';
import EmbedWidget from '../components/admin/Integrations/EmbedWidget';
import Customers from '../components/admin/Customers';
import Customer from '../components/admin/Customers/Show';
import CustomerFinancialReport from '../components/admin/Customers/FinancialReport';
import ProtectedRoute from '../components/common/ProtectedRoute';
import { Roles } from '../constants';
import TaxRates from '../components/admin/taxRates';
import TaxRateForm from '../components/admin/taxRates/Form';
import TaxRate from '../components/admin/taxRates/Show';
import Projects from '../components/admin/Projects';
import ProjectForm from '../components/admin/Projects/Form';
import Project from '../components/admin/Projects/Show';
import Units from '../components/admin/Units/List';
import UnitForm from '../components/admin/Units/Form';
import Unit from '../components/admin/Units/Show';
import OptionTypes from '../components/admin/OptionTypes/List';
import OptionTypeForm from '../components/admin/OptionTypes/Form';
import OptionType from '../components/admin/OptionTypes/Show';
import Deals from '../components/admin/Sales/Deals';
import Contracts from '../components/admin/Sales/Contracts';
import BuildingModel from '../components/admin/buildingModels/Show';
import BuildingModels from '../components/admin/buildingModels/List';
import BuildingModelForm from '../components/admin/buildingModels/Form';
import Deposits from '../components/admin/Deposits';
import Tours from '../components/admin/Tours/List';
import Packages from '../components/admin/packages/List';
import PackageForm from '../components/admin/packages/Form';
import Package from '../components/admin/packages/Show';
import QuickPossessions from '../components/admin/QuickPossessions';
import QuickPossession from '../components/admin/QuickPossessions/Show';
import MyProfile from '../components/admin/MyProfile/Show';
import MyProfileForm from '../components/admin/MyProfile/Form';
import KnowledgeHubFrame from '../components/admin/KnowledgeHubFrame';
import SocialMedia from '../components/admin/Integrations/SocialMedia';
import Cities from '../components/admin/cities/List';
import CityForm from '../components/admin/cities/Form';
import City from '../components/admin/cities/Show';
import Teams from '../components/admin/teams/List';
import Team from '../components/admin/teams/Show';
import ProjectBuildings from '../components/admin/projectBuildings/List';
import ProjectBuildingForm from '../components/admin/projectBuildings/Form';
import ProjectBuilding from '../components/admin/projectBuildings/Show';
import ExternalDataSources from '../components/admin/Integrations/ExternalDataSources';
import ExternalDataSource from '../components/admin/Integrations/ExternalDataSources/Show';
import ExternalDataSourceForm from '../components/admin/Integrations/ExternalDataSources/Form';
import Messages from '../components/admin/Messages/List';
import Webhooks from '../components/admin/Integrations/Webhooks/List';
import WebhookDeliveries from '../components/admin/Integrations/Webhooks/WebhookDeliveries';
import PaymentMethods from '../components/admin/Integrations/PaymentMethods';
import DocuSign from '../components/admin/Integrations/DocuSign';
import ArchivedCompanies from '../components/admin/companies/Archived';
import MasterAdminDashboard from '../components/admin/MasterAdminDashboard';
import SendGrid from '../components/admin/Integrations/SendGrid';
import SendGridContactLists from '../components/admin/Integrations/SendGrid/ContactLists/List';
import SendGridContacts from '../components/admin/Integrations/SendGrid/ContactLists/Contacts';
import Tracking from '../components/admin/Integrations/Tracking';
import PropertyTaxRates from '../components/admin/propertyTaxRates/List';
import PropertyTaxRateForm from '../components/admin/propertyTaxRates/Form';
import Insights from '../components/admin/Insights';

const Admin = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    AuthService.createDeviceToken();

    if (AuthService.isAuthenticated()) {
      AuthService.login(false)
        .finally(() => {
          setMounted(true);
        });
    } else if (isBrowser()) navigate('/auth/login');

    return () => {};
  }, []);

  if (!mounted) return null;

  return (
    <Layout>
      <Router basepath="/admin">
        <ProtectedRoute path="/dashboard" component={MasterAdminDashboard} only={[Roles.MASTER_ADMIN, Roles.TEC_SUPPORT]} />

        <ProtectedRoute path="/overview" component={Overview} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />

        <ProtectedRoute path="/communities" component={Communities} />
        <ProtectedRoute path="/communities/form" component={CommunityForm} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/communities/:id" component={Community} />

        <ProtectedRoute path="/productTypes" component={ProductTypes} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/productTypes/form" component={ProductTypeForm} />
        <ProtectedRoute path="/productTypes/:id" component={ProductType} />

        <ProtectedRoute path="/models" component={Models} />
        <ProtectedRoute path="/models/form" component={ModelForm} />
        <ProtectedRoute path="/models/:id" component={Model} />

        <ProtectedRoute path="/quickPossessions" component={QuickPossessions} />
        <ProtectedRoute path="/quickPossessions/scheduledToArchive" component={QuickPossessions} />
        <ProtectedRoute path="/quickPossessions/:id" component={QuickPossession} />

        <ProtectedRoute path="/palettes" component={Palettes} />
        <ProtectedRoute path="/palettes/form" component={PaletteForm} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/palettes/:id" component={Palette} />

        <ProtectedRoute path="/packages" component={Packages} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/packages/form" component={PackageForm} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/packages/:id" component={Package} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />

        <ProtectedRoute path="/taxRates" component={TaxRates} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/taxRates/form" component={TaxRateForm} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/taxRates/:id" component={TaxRate} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />

        <ProtectedRoute path="/settings" component={Settings} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/tours" component={Tours} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />

        <ProtectedRoute path="/customers" component={Customers} />
        <ProtectedRoute path="/customers/deleted" component={Customers} />
        <ProtectedRoute path="/customers/:id" component={Customer} />
        <ProtectedRoute path="/customers/:id/financialReport" component={CustomerFinancialReport} />

        <ProtectedRoute path="/insights" component={Insights} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />

        <ProtectedRoute path="/projects" component={Projects} />
        <ProtectedRoute path="/projects/form" component={ProjectForm} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/projects/:id" component={Project} />

        <ProtectedRoute path="/buildingModels" component={BuildingModels} />
        <ProtectedRoute path="/buildingModels/form" component={BuildingModelForm} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/buildingModels/form/:id" component={BuildingModelForm} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/buildingModels/:id" component={BuildingModel} />

        <ProtectedRoute path="/units" component={Units} />
        <ProtectedRoute path="/units/form" component={UnitForm} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/units/:id" component={Unit} />

        <ProtectedRoute path="/optionTypes" component={OptionTypes} />
        <ProtectedRoute path="/optionTypes/form" component={OptionTypeForm} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/optionTypes/:id" component={OptionType} />

        <ProtectedRoute path="/multifamily/palettes" component={Palettes} />
        <ProtectedRoute path="/multifamily/palettes/form" component={PaletteForm} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/multifamily/palettes/:id" component={Palette} />

        <ProtectedRoute path="/multifamily/packages" component={Packages} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/multifamily/packages/form" component={PackageForm} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/multifamily/packages/:id" component={Package} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />

        <ProtectedRoute path="/projectBuildings" component={ProjectBuildings} />
        <ProtectedRoute path="/projectBuildings/form" component={ProjectBuildingForm} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/projectBuildings/form/:id" component={ProjectBuildingForm} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/projectBuildings/:id" component={ProjectBuilding} />

        <ProtectedRoute path="/integrations" component={Integrations} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/integrations/customCode" component={CustomCode} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/integrations/hubSpot" component={HubSpot} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/integrations/importData" component={ImportData} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/integrations/exportData" component={ExportData} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/integrations/zapier" component={Zapier} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/integrations/googleSitemap" component={GoogleSitemap} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/integrations/embedWidget" component={EmbedWidget} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/integrations/socialMedia" component={SocialMedia} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/integrations/externalDataSources" component={ExternalDataSources} only={[Roles.MASTER_ADMIN, Roles.TEC_SUPPORT]} />
        <ProtectedRoute path="/integrations/externalDataSources/:dataProvider" component={ExternalDataSource} only={[Roles.MASTER_ADMIN, Roles.TEC_SUPPORT]} />
        <ProtectedRoute path="/integrations/externalDataSources/:dataProvider/form" component={ExternalDataSourceForm} only={[Roles.MASTER_ADMIN, Roles.TEC_SUPPORT]} />
        <ProtectedRoute path="/integrations/webhooks" component={Webhooks} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/integrations/webhooks/:id/deliveries" component={WebhookDeliveries} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/integrations/paymentMethods" component={PaymentMethods} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/integrations/docuSign" component={DocuSign} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/integrations/sendGrid" component={SendGrid} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/integrations/sendGrid/contactLists" component={SendGridContactLists} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/integrations/sendGrid/contactLists/:id/contacts" component={SendGridContacts} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/integrations/tracking" component={Tracking} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />

        <ProtectedRoute path="/mortgageRate" component={MortgageRate} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />

        <ProtectedRoute path="/companies" component={Companies} only={[Roles.MASTER_ADMIN, Roles.TEC_SUPPORT]} />
        <ProtectedRoute path="/companies/archived" component={ArchivedCompanies} only={[Roles.MASTER_ADMIN, Roles.TEC_SUPPORT]} />
        <ProtectedRoute path="/companies/form" component={CompanySettings} only={[Roles.MASTER_ADMIN, Roles.TEC_SUPPORT]} />
        <ProtectedRoute path="/companies/form/:id" component={CompanySettings} only={[Roles.MASTER_ADMIN, Roles.TEC_SUPPORT]} />

        <ProtectedRoute path="/myCompany" component={EditCompany} only={[Roles.COMPANY_ADMIN, Roles.SALES_LEAD, Roles.TEC_SUPPORT]} />

        <ProtectedRoute path="/users" component={Users} />
        <ProtectedRoute path="/users/:id" component={UserShow} />

        <ProtectedRoute path="/sales/deals" component={Deals} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/sales/contracts" component={Contracts} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />

        <ProtectedRoute path="/deposits" component={Deposits} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/documents" component={Documents} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />

        <ProtectedRoute path="/myProfile" component={MyProfile} />
        <ProtectedRoute path="/myProfile/form" component={MyProfileForm} />

        <KnowledgeHubFrame path="/knowledge" />

        <ProtectedRoute path="/cities" component={Cities} />
        <ProtectedRoute path="/cities/form" component={CityForm} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
        <ProtectedRoute path="/cities/:id" component={City} />

        <ProtectedRoute path="/teams" component={Teams} />
        <ProtectedRoute path="/teams/:id" component={Team} />

        <ProtectedRoute path="/messages" component={Messages} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />

        <ProtectedRoute path="/propertyTaxRates" component={PropertyTaxRates} only={[Roles.MASTER_ADMIN, Roles.TEC_SUPPORT]} />
        <ProtectedRoute path="/propertyTaxRates/form" component={PropertyTaxRateForm} only={[Roles.MASTER_ADMIN, Roles.TEC_SUPPORT]} />

        <ProtectedRoute path="/" component={Overview} except={[Roles.COMPANY_USER, Roles.SALES_USER]} />
      </Router>
    </Layout>
  );
};
export default Admin;
