import React from 'react';
import PropTypes from 'prop-types';

import { getQuickPossessionColumnName } from '../../../../../../../utils/enumUtils';
import { QuickPossessionColumns } from '../../../utils';

const CustomColumnName = ({ column }) => {
  const name = getQuickPossessionColumnName(column);

  return (
    <th>
      {name}
    </th>
  );
};

CustomColumnName.propTypes = {
  column: PropTypes.oneOf(Object.values(QuickPossessionColumns)).isRequired
};

CustomColumnName.defaultProps = {};

export default CustomColumnName;
