import React from 'react';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';

import './styles.scss';
import ListHeader from './ListHeader';
import ListItem from './ListItem';

const ListView = ({ quickPossessions }) => (
  <Table responsive bordered id="quick-possessions-table">
    <thead>
      <ListHeader />
    </thead>
    <tbody>
      {
        quickPossessions.map((quickPossession) => (
          <ListItem key={`quick-possession-${quickPossession.id}`} quickPossession={quickPossession} />
        ))
      }
    </tbody>
  </Table>
);

ListView.propTypes = {};

ListView.defaultProps = {};

export default connect((store) => ({
  quickPossessions: store.quickPossessions.quickPossessions,
}))(ListView);
