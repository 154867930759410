import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';

import useValidSelectedColumns from '../../hooks/useValidSelectedColumns';
import CustomColumnValue from './CustomColumnValue';
import { isAdmin } from '../../../../../../utils/authUtils';
import ActionsMenu from '../../../../../global/ActionsMenu';
import { deleteUnit } from '../../../../../../store/actions/unitActions';
import { getColumnTdClass } from '../utils';

const ListItem = ({ unit, ...props }) => {
  const columns = useValidSelectedColumns();

  const onEdit = () => {
    navigate(`/admin/units/form?id=${unit.id}`);
  };

  const onDelete = () => {
    props.deleteUnit(unit.id);
  };

  return (
    <tr>
      {
        columns.map((column) => (
          <td key={`unit-cell-${unit.id}-${column}`} className={getColumnTdClass(column)}>
            <CustomColumnValue column={column} unit={unit} />
          </td>
        ))
      }

      {isAdmin() && (
        <td className="text-right">
          <ActionsMenu
            item={unit}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </td>
      )}
    </tr>
  );
};

ListItem.propTypes = {
  unit: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect(() => ({}), {
  deleteUnit
})(ListItem);
