import api from '../../../../../../../../../api';
import {
  downloadOnfidoFileMutation,
} from '../../../../../../../../../graphql';
import { CustomerDocumentTypes } from '../../../utils';

export function getType(documentType) {
  switch (documentType) {
    case CustomerDocumentTypes.ONFIDO_DOCUMENT_FRONT_PHOTO:
    case CustomerDocumentTypes.ONFIDO_DOCUMENT_BACK_PHOTO:
      return 'document';
    case CustomerDocumentTypes.ONFIDO_DOCUMENT_SELFIE:
      return 'selfie';
    case CustomerDocumentTypes.ONFIDO_DOCUMENT_REPORT:
      return 'report';
    default:
      return null;
  }
}

export async function downloadOnfidoFileAsync(id, type, fileName) {
  const input = {
    id,
    type,
    fileName,
  };
  return api.graphql(downloadOnfidoFileMutation, { input })
    .then(({ data: { item } }) => item);
}
