import { useSelector } from 'react-redux';

const useIsEmbeddedReserveEnabled = () => {
  const { currentCompany } = useSelector((store) => ({
    currentCompany: store.companies.currentCompany
  }));
  return !!currentCompany?.buyNowSettings?.enabled && !!currentCompany.enableEmbeddedReserve;
};

export default useIsEmbeddedReserveEnabled;
