import React, { useState } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input
} from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { MdContentCopy } from 'react-icons/md';
import v from 'voca';

import { ModalNames } from '../../../constants';
import ModalService from '../../../services/ModalService';
import SocialShare from '../SocialShare';
import { isQuickPossessionOrUnit } from './utils';
import './styles.scss';

const i18nOpts = { scope: 'components.global.directLinkModal.index' };

function onClose() {
  ModalService.close(ModalNames.DIRECT_LINK_MODAL);
}

function onCopy() {
  toast.info(i18n.t('messages.copied', i18nOpts));
  onClose();
}

const DirectLinkModal = ({
  opened, params, currentCompany
}) => {
  const { path, label, isPreview } = params;
  if (v.isBlank(path)) return null;

  const isEnableCheckBox = isQuickPossessionOrUnit(path);
  const title = isPreview ? i18n.t('previewTitle', i18nOpts) : i18n.t('title', i18nOpts);
  const [isCheckedReserveNow, setIsCheckedReserveNow] = useState(false);

  const buildUrl = () => {
    const domain = currentCompany.domains[0];
    let url = new URL(path, domain).toString();

    if (isCheckedReserveNow) url += '/reserve';

    return url;
  };

  const onReserveNowChange = (event) => {
    const isChecked = event.target.checked;
    setIsCheckedReserveNow(isChecked);
  };

  const url = buildUrl();

  return (
    <Modal isOpen={opened} className="direct-link-modal">
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <span className="text-uppercase mb-3">{label}</span>
        <div className="link-container">
          <a href={url} target="_blank" rel="noreferrer">
            {url}
          </a>
        </div>
        {currentCompany.buyNowSettings.enabled && !isPreview && isEnableCheckBox && (
          <div className="ml-2 mt-4">
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="generateReserveNowLink"
                  id="generateReserveNowLink"
                  checked={isCheckedReserveNow}
                  onChange={onReserveNowChange}
                />
                {i18n.t('reserveNowCheckbox', i18nOpts)}
              </Label>
            </FormGroup>
          </div>
        )}
        {!isPreview && (
          <SocialShare
            url={url}
            itemName={label}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <CopyToClipboard text={url} onCopy={onCopy} className="mr-3">
          <Button outline color="primary" className="text-uppercase">
            <MdContentCopy size="1rem" className="mr-2 align-middle" />
            {i18n.t('buttons.copy', i18nOpts)}
          </Button>
        </CopyToClipboard>
        <Button outline color="secondary" onClick={onClose}>
          {i18n.t('buttons.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  opened: store.modals[ModalNames.DIRECT_LINK_MODAL]?.opened || false,
  params: store.modals[ModalNames.DIRECT_LINK_MODAL]?.params || {}
}), {})(DirectLinkModal);
