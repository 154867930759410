import React from 'react';
import PropTypes from 'prop-types';

import { getUnitColumnName } from '../../../../../../../utils/enumUtils';
import { UnitColumns } from '../../../utils';
import { getColumnThClass } from '../../utils';

const CustomColumnName = ({ column }) => {
  const name = getUnitColumnName(column);
  const cellClass = getColumnThClass(column);

  return (
    <th className={cellClass}>
      {name}
    </th>
  );
};

CustomColumnName.propTypes = {
  column: PropTypes.oneOf(Object.values(UnitColumns)).isRequired
};

CustomColumnName.defaultProps = {};

export default CustomColumnName;
