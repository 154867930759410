import React from 'react';
import {
  Col, Input, Label, Row
} from 'reactstrap';
import i18n from 'i18n-js';
import * as PropTypes from 'prop-types';
import { MdInfoOutline } from 'react-icons/md';

import useIsVerifiedEnabled from '../../../../../hooks/useIsVerifiedEnabled';
import useIsReserveNowEnabled from '../../../../../hooks/useIsReserveNowEnabled';

const i18nOpts = { scope: 'components.admin.integrations.hubSpot.fieldsMapping.index' };

const FieldsMapping = ({ form, onTextChange }) => {
  const isVerifiedEnabled = useIsVerifiedEnabled();
  const isReserveNowEnabled = useIsReserveNowEnabled();

  return (
    <Row>
      <Col lg="10" xl="6">
        <div className="table-responsive">
          <table className="mb-4">
            <thead>
              <tr>
                <th className="py-2 text-center text-uppercase">
                  {i18n.t('ownlyField', i18nOpts)}
                </th>
                <th className="py-2 text-center text-uppercase">
                  {i18n.t('hubSpotField', i18nOpts)}
                </th>
                <th className="py-2 text-center text-uppercase" style={{ width: '40%' }}>
                  {i18n.t('customValue', i18nOpts)}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="align-top py-2 pr-5">
                  <Label for="casl" className="mt-1">{i18n.t('casl', i18nOpts)}</Label>
                </td>
                <td className="align-top">
                  <Input
                    type="text"
                    name="casl"
                    id="casl"
                    value={form.casl || ''}
                    onChange={onTextChange}
                  />
                </td>
                <td className="align-top pl-2">
                  <Input
                    type="text"
                    name="caslFieldCustomValue"
                    id="caslFieldCustomValue"
                    value={form.caslFieldCustomValue || ''}
                    onChange={onTextChange}
                    placeholder={i18n.t('placeholders.caslCustomValue', i18nOpts)}
                  />
                  <div className="col-gray-500">
                    <MdInfoOutline />
                    <small className="ml-1">{i18n.t('messages.caslCustomValue', i18nOpts)}</small>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="py-2 pr-5">
                  <Label for="leadSource" className="mt-2">{i18n.t('leadSource', i18nOpts)}</Label>
                </td>
                <td>
                  <Input
                    type="text"
                    name="leadSource"
                    id="leadSource"
                    value={form.leadSource || ''}
                    onChange={onTextChange}
                  />
                </td>
                <td />
              </tr>
              <tr>
                <td className="py-2 pr-5">
                  <Label for="buildLink" className="mt-2">{i18n.t('buildLink', i18nOpts)}</Label>
                </td>
                <td>
                  <Input
                    type="text"
                    name="buildLink"
                    id="buildLink"
                    value={form.buildLink || ''}
                    onChange={onTextChange}
                  />
                </td>
                <td />
              </tr>
              <tr>
                <td className="py-2 pr-5">
                  <Label for="community" className="mt-2">{i18n.t('community', i18nOpts)}</Label>
                </td>
                <td>
                  <Input
                    type="text"
                    name="community"
                    id="community"
                    value={form.community || ''}
                    onChange={onTextChange}
                  />
                </td>
                <td />
              </tr>
              <tr>
                <td className="py-2 pr-5">
                  <Label for="model" className="mt-2">{i18n.t('model', i18nOpts)}</Label>
                </td>
                <td>
                  <Input
                    type="text"
                    name="model"
                    id="model"
                    value={form.model || ''}
                    onChange={onTextChange}
                  />
                </td>
                <td />
              </tr>
              {isVerifiedEnabled && (
                <tr>
                  <td className="py-2 pr-5">
                    <Label for="verifiedStatusField" className="mt-2">{i18n.t('verifiedStatusField', i18nOpts)}</Label>
                  </td>
                  <td>
                    <Input
                      type="text"
                      name="verifiedStatusField"
                      id="verifiedStatusField"
                      value={form.verifiedStatusField || ''}
                      onChange={onTextChange}
                    />
                  </td>
                  <td />
                </tr>
              )}

              {isReserveNowEnabled && (
                <tr>
                  <td className="py-2 pr-5">
                    <Label for="reservationStepField" className="mt-2">{i18n.t('reservationStepField', i18nOpts)}</Label>
                  </td>
                  <td>
                    <Input
                      type="text"
                      name="reservationStepField"
                      id="reservationStepField"
                      value={form.reservationStepField || ''}
                      onChange={onTextChange}
                    />
                  </td>
                  <td />
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Col>
    </Row>
  );
};

FieldsMapping.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onTextChange: PropTypes.func.isRequired
};

FieldsMapping.defaultProps = {};

export default FieldsMapping;
