import React from 'react';

import useValidSelectedColumns from '../../hooks/useValidSelectedColumns';
import CustomColumnName from './CustomColumnName';
import { isCompanyAdmin, isMasterAdmin, isSalesLead } from '../../../../../../utils/authUtils';

const ListHeader = () => {
  const columns = useValidSelectedColumns();
  const isAdmin = isMasterAdmin() || isCompanyAdmin() || isSalesLead();

  return (
    <tr>
      {
        columns.map((column) => (
          <CustomColumnName key={`quick-possession-header-${column}`} column={column} />
        ))
      }
      {isAdmin && (
        <th>&nbsp;</th>
      )}
    </tr>
  );
};

ListHeader.propTypes = {};

ListHeader.defaultProps = {};

export default ListHeader;
